<template>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title align="center">
              <v-spacer></v-spacer>
              Page not found
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-actions>
              <v-btn
                class="secondary"
                to="/admin"
              >Back to CATS</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  name: '404'
}
</script>

<style scoped>

</style>
